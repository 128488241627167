exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-artificial-intelligence-js": () => import("./../../../src/pages/artificial-intelligence.js" /* webpackChunkName: "component---src-pages-artificial-intelligence-js" */),
  "component---src-pages-blog-1-js": () => import("./../../../src/pages/Blog1.js" /* webpackChunkName: "component---src-pages-blog-1-js" */),
  "component---src-pages-blog-2-js": () => import("./../../../src/pages/Blog2.js" /* webpackChunkName: "component---src-pages-blog-2-js" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-cloud-adoption-js": () => import("./../../../src/pages/cloud-adoption.js" /* webpackChunkName: "component---src-pages-cloud-adoption-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-crm-js": () => import("./../../../src/pages/crm.js" /* webpackChunkName: "component---src-pages-crm-js" */),
  "component---src-pages-custom-software-development-js": () => import("./../../../src/pages/custom-software-development.js" /* webpackChunkName: "component---src-pages-custom-software-development-js" */),
  "component---src-pages-cyber-security-js": () => import("./../../../src/pages/cyber-security.js" /* webpackChunkName: "component---src-pages-cyber-security-js" */),
  "component---src-pages-data-science-analytics-js": () => import("./../../../src/pages/data-science-analytics.js" /* webpackChunkName: "component---src-pages-data-science-analytics-js" */),
  "component---src-pages-digital-transformation-js": () => import("./../../../src/pages/digital-transformation.js" /* webpackChunkName: "component---src-pages-digital-transformation-js" */),
  "component---src-pages-ecommerce-solution-js": () => import("./../../../src/pages/ecommerce-solution.js" /* webpackChunkName: "component---src-pages-ecommerce-solution-js" */),
  "component---src-pages-energy-utilities-js": () => import("./../../../src/pages/energy-utilities.js" /* webpackChunkName: "component---src-pages-energy-utilities-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-financial-sector-js": () => import("./../../../src/pages/financial-sector.js" /* webpackChunkName: "component---src-pages-financial-sector-js" */),
  "component---src-pages-government-js": () => import("./../../../src/pages/government.js" /* webpackChunkName: "component---src-pages-government-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-internet-of-thing-js": () => import("./../../../src/pages/internet-of-thing.js" /* webpackChunkName: "component---src-pages-internet-of-thing-js" */),
  "component---src-pages-internship-job-js": () => import("./../../../src/pages/internship-job.js" /* webpackChunkName: "component---src-pages-internship-job-js" */),
  "component---src-pages-logistics-transportation-js": () => import("./../../../src/pages/logistics-transportation.js" /* webpackChunkName: "component---src-pages-logistics-transportation-js" */),
  "component---src-pages-mobile-app-solution-js": () => import("./../../../src/pages/mobile-app-solution.js" /* webpackChunkName: "component---src-pages-mobile-app-solution-js" */),
  "component---src-pages-mobile-application-service-js": () => import("./../../../src/pages/mobile-application-service.js" /* webpackChunkName: "component---src-pages-mobile-application-service-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-professional-service-js": () => import("./../../../src/pages/professional-service.js" /* webpackChunkName: "component---src-pages-professional-service-js" */),
  "component---src-pages-retail-js": () => import("./../../../src/pages/retail.js" /* webpackChunkName: "component---src-pages-retail-js" */),
  "component---src-pages-software-solution-js": () => import("./../../../src/pages/software-solution.js" /* webpackChunkName: "component---src-pages-software-solution-js" */),
  "component---src-pages-telecommunication-js": () => import("./../../../src/pages/telecommunication.js" /* webpackChunkName: "component---src-pages-telecommunication-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-web-portal-js": () => import("./../../../src/pages/web-portal.js" /* webpackChunkName: "component---src-pages-web-portal-js" */),
  "component---src-pages-why-choose-us-js": () => import("./../../../src/pages/why-choose-us.js" /* webpackChunkName: "component---src-pages-why-choose-us-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

